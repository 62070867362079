<script setup lang="ts">
import { PopoverContent, PopoverRoot, PopoverTrigger } from "radix-vue";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";

const breakpoints = useBreakpoints(breakpointsTailwind);
const mdAndLarger = breakpoints.greaterOrEqual("md"); // sm and larger
const isBurgerMenuOpen = ref(false);
const isAdmin = useIsAdmin();
const role = isAdmin.value ? "admin" : "support";
const adminLinks = getLayoutLinks(role);
</script>

<template>
  <div class="content-wrapper" :class="$style.adminLayoutHeader">
    <!--  <UIButton href="http://localhost:3002?configurator" target="_blank">
        <Icon name="lucide:settings" />Widgets
      </UIButton>-->
    <div v-if="mdAndLarger" class="breakout" :class="$style.adminDesktopHeader">
      <div :class="$style.adminLinksDesktop">
        <NuxtLink
          v-for="item in adminLinks"
          :key="item.name"
          :to="item.to"
          :class="$style.adminLink"
          :exact-active-class="$style.adminLinkActive"
        >
          {{ item.name }}
        </NuxtLink>
      </div>
      <div :class="$style.adminHeaderRight">
        <ExchangeRate />
        <TimezoneToggle />
        <ThemeToggle />
        <AccountMenuToggle />
      </div>
    </div>
    <div v-else :class="$style.adminDesktopHeader">
      <PopoverRoot v-model:open="isBurgerMenuOpen">
        <PopoverTrigger
          :class="$style.burgerMenuWrapper"
          @click="isBurgerMenuOpen = !isBurgerMenuOpen"
        >
          <IconBurgerMenu
            :is-active="isBurgerMenuOpen"
          />
        </PopoverTrigger>
        <PopoverContent
          :class="[$style.mobileMenu, { 'is-open': isBurgerMenuOpen }]"
        >
          <div :class="$style.mobileMenuLinks">
            <NuxtLink
              v-for="item in adminLinks"
              :key="item.name"
              :to="item.to"
              :class="$style.adminLink"
              :exact-active-class="$style.adminLinkActive"
            >
              {{ item.name }}
            </NuxtLink>
          </div>
        </PopoverContent>
      </PopoverRoot>
      <div :class="$style.adminHeaderRight">
        <ExchangeRate />
        <TimezoneToggle />
        <ThemeToggle />
        <AccountMenuToggle />
      </div>
    </div>
  </div>
</template>

<style module>
.burgerMenuWrapper {
  width: 48px;
  flex-shrink: 0;
  color: var(--text-color);
}

.adminLayoutHeader {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 50;
  border-bottom: 1px var(--border-color) solid;
  margin-bottom: var(--size-fluid-3);
  background-color: var(--background);
}

.adminDesktopHeader {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.adminLinksDesktop {
  display: flex;
  align-items: center;
  overflow: auto;
}

.adminHeaderRight {
  display: flex;
  margin-inline-start: auto;
  align-items: center;
  gap: 0.5rem;
  overflow: auto;
}

.mobileMenu {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 1rem;
  background: var(--bgc-card);
  width: var(--radix-popover-content-available-width);
  height: var(--radix-popover-content-available-height);
  transition: transform 300ms ease;

  &[data-state="closed"] {
    animation: slideOut 300ms ease;
  }

  &[data-state="open"] {
    animation: slideIn 300ms ease;
  }
}

.mobileMenuLinks {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
}

.adminLink {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-width: 2px;
  border-color: transparent;
  padding: var(--tw-size-3);
  text-align: center;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  color: var(--text-muted);
  height: 100%;
  font-size: 0.875rem;

  &:hover {
    color: var(--text-muted-hover);
  }
}

.adminLinkActive {
  border-color: var(--Border__Process);
  color: var(--text-color);

  &:hover {
    color: var(--text-color);
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
</style>
