<script setup lang="ts">
import { TooltipProvider } from "radix-vue";
import { Toaster } from "vue-sonner";
import AdminLayoutHeader from "~/components/AdminLayoutHeader.vue";

useColorMode();
</script>

<template>
  <ClientOnly>
    <UIPageLoader />
    <TooltipProvider>
      <AdminLayoutHeader />
      <main class="content-wrapper">
        <NuxtPage />
      </main>
    </TooltipProvider>
    <Toaster rich-colors />
  </ClientOnly>
</template>
